import React from 'react';
import './styles.css';

export default class Company extends React.Component{
    render() {
        return (
            <div className="cont responsive_sm company">
              <h3 className="ttl">&#36;echo "Company"</h3>
              <div className="header-spacer" />
              <table className="company-table">
                  <tbody className="responsive_table">

                      <tr className="company-ttl">Headquarters</tr>
                      <tr>
                        <td>Company</td><td>Namespace Inc.</td>
                      </tr>
                      <tr>
                        <td>Address</td><td>Graces Bldg 2F, 5-11-24, Minamiaoyama, Minato, Tokyo, Japan 107-0062</td>
                      </tr>
                      <tr>
                        <td>CEO</td><td>Asei Muneoka</td>
                      </tr>
                  </tbody>
                </table>
                <div className="recruitment-hr" />
                <div className="header-spacer" />
                <table className="company-table">
                  <tbody className="responsive_table">
                      <tr className="company-ttl">Nepal branch</tr>
                      <tr>
                        <td>Company</td><td>Chulo Solutions Pvt. Ltd.</td>
                      </tr>
                      <tr>
                        <td>Address</td><td>Lokeshwor Tole, Kumaripati, Lalitpur Municipality, Bagmati Province, Nepal</td>
                      </tr>
                      <tr>
                        <td>Director</td><td>Aadesh Shrestha</td>
                      </tr>
                  </tbody>
              </table>
            </div>
        );
    }
}