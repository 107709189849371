import React from 'react';
import ServiceMenu from '../../components/serviceMenu';
import './styles.css';
const pageTitle = "Branding";
const pageDescription = "By branding, we convey value of your product not at superficial level, but at essential level. "
const branding = [{
    title: "Brand building",
    description: "We help making vision, mission and value, which is a concept making, the core part of branding, ",
    thumbnail: "images/branding/brand-building/branding1"
}, {
    title: "Visual design",
    description: "We propose and make visual designs based on brand concept. We can also do copy writing and photo shooting.",
    thumbnail: "images/branding/visual-design/branding2"
}, {
    title: "Logo design",
    description: "We make logos that can represent core concept which underlies your company, service or goods.",
    thumbnail: "images/branding/logo-design/branding3"
}, {
    title: "UI/UX design",
    description: "Even on end-users’ frontend app side, we reflect your brand concept so users can feel the brand when using the service.",
    thumbnail: "images/branding/ui-ux-design/branding4"
}]

export default class Branding extends React.Component {
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div className="cont">
                <h3 className="ttl">&#36;echo "Service"</h3>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-4" />
                </div>
                <div id="sf_pc" className="menu-line-top"></div>
                <h3 className="page-ttl">{pageTitle}</h3>
                <p className="page-desc">{pageDescription}</p>
                <div className="service-dev-container">
                    {branding.map((brand) =>
                        <div class="sd-item">
                            <img className="sd-item-img" src={require(`../../${brand.thumbnail}@2x.png`)} className="sd-image" alt={brand.title} />
                            <p className="sd-item-ttl">{brand.title}</p>
                            <div className="sd-line"></div>
                            <p className="sd-item-desc">{brand.description}</p>
                        </div>
                    )}
                </div>
                <h3 className="branding-sf-ttl">Sample Flow</h3>
                <p className="sf-desc">From zero to one, we support all till end-users can use your brand.</p>

                <img className="branding-sf-img" id="sf_pc" src={require('../../images/branding/sample-flow/branding5@2x.png')} alt="sample flow" />
                <img className="branding-sf-img" id="sf_sp" src={require('../../images/branding/sample-flow/branding5_sp@2x.png')} alt="sample flow" />

                <div className="menu-line-bottom"></div>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-4" />
                </div>
                <div id="sf_sp">
                    <ServiceMenu tabs="tabs-4" />
                </div>
            </div>
        )
    }
}