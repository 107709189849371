
import React, { Component } from 'react'
import Image from './../image/Index';
import logo from './../../images/logo.png';
import './Style.css';
import { NavLink, withRouter } from 'react-router-dom';

const items = ['about', 'service', 'company', 'portfolio', 'recruitment', 'contact']
const serviceItems = ['service-development', 'ai-development','rpa-consulting','branding','ecommerce' ]
// Commented out the profile page.

class Menu extends Component {
  render() {

    const { location } = this.props
    console.log("This is the width", this.props.opening && this.props.width > 1000)

    return (
      <div className="container-menu left">
        <div className={'sp sp-nav ' + (this.props.opening ? 'active' : '')}>
          <div className="navToggle">
            <div
              className={'menu-trigger ' + (this.props.opening ? 'white' : 'black')}
              onClick={this.props.openingToggle}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="logo">
          <NavLink to="/"><Image src={logo} /></NavLink>
        </div>

        {/* <label className="hamburger-icon" for="toggle">&#9776;</label>
        <input type="checkbox" id="toggle" /> */}

        <nav className={'gnav ' + (this.props.width >= 639 ? 'pc' : 'spNav') + (this.props.opening ? ' show' : ' hide')}>
          <ul className={'gNav'}>
            {items.map((item, index) =>
              <li key={item} onClick={this.props.openingToggle}>
                <NavLink to={item}>
                  {index === 1 && serviceItems.includes(location.pathname.replace('/', '')) ? <>
                      <span className={index === 1 && serviceItems.includes(location.pathname.replace('/', '')) ? 'dragLeft' : 'regular'}>
                        <span className={index === 1 && serviceItems.includes(location.pathname.replace('/', '')) ? 'showHash' : 'hideHash'}># </span>
                        00{index + 1} | {item}
                      </span>
                  </> : <>
                      <span className={item === location.pathname.replace('/', '') ? 'dragLeft' : 'regular'}>
                        <span className={item === location.pathname.replace('/', '') ? 'showHash' : 'hideHash'}># </span>
                        00{index + 1} | {item}
                      </span>
                    </>}
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
    )
  }
}

export default withRouter(Menu);
