import React from 'react';
import './styles.css';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="container-footer">
        <p className="copyright">
          Copyright Namespace Inc. 2016-{(new Date().getFullYear())}
        </p>
      </div>
    );
  }
}
