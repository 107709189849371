import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import * as ServicesList from '../ServiceList';

export default class serviceMenu extends React.Component {

    render() {
        const { tabs } = this.props
        return (
            <ul className="sd-menu" >
                {ServicesList.services.map((service) =>
                    <li>
                        <Link to={service.link} className={tabs === service.tabs && "active"}>
                            {service.title}
                        </Link>
                    </li>
                )}
            </ul>
        );

    };
};