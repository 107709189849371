import React from 'react';
import ServiceMenu from '../../components/serviceMenu';
import './styles.css';
const pageTitle = "RPA Consulting";
const pageDescription = "We propose and develop technologies to achieve work efficiency and optimize workflow in different areas of business"

export default class Service extends React.Component {
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    
    render() {
        return (
            <div className="cont">
                <h3 className="ttl">&#36;echo "Service"</h3>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-3" />
                </div>
                <div id="sf_pc" className="menu-line-top"></div>
                <h3 className="page-ttl">{pageTitle}</h3>
                <p className="sd-desc">{pageDescription}</p>
                <div id="sf_pc">
                    <h3 className="rpa-ttl">Issues at Workplace</h3>
                    <h3 className="rpa-ttl-1">Automation by robotics</h3>
                    <img className="rpa-img" src={require("../../images/rpaConsulting/rpa/rpa1_pc@2x.png")} />
                </div>

                <div id="sf_sp">
                    <h3 className="rpa-ttl">Issues at Workplace</h3>
                    <img className="rpa-img" src={require("../../images/rpaConsulting/rpa/rpa1_sp1@2x.png")} />
                    <h3 className="rpa-ttl-1">Automation by robotics</h3>
                    <img className="rpa-img" src={require("../../images/rpaConsulting/rpa/rpa1_sp2@2x.png")} />
                </div>

                <h3 className="rpa-sf-ttl">Sample Flow</h3>
                <p className="sf-desc">By covering from the beginning to the end,<br />we are the one-stop RPA solution for you.</p>
                <img id="sf_pc" className="rpa-sf-img" src={require("../../images/rpaConsulting/sample-flow/rpa2_pc@2x.png")} />
                <img id="sf_sp" className="rpa-sf-img" src={require("../../images/rpaConsulting/sample-flow/rpa2_sp@2x.png")} />

                <div className="menu-line-bottom"></div>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-3" />
                </div>
                <div id="sf_sp">
                    <ServiceMenu tabs="tabs-3" />
                </div>
            </div>
        )
    }
}