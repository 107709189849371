import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import * as PortfolioList from '../../components/PortfolioList';

export default class Portfolio extends React.Component {
  render() {
    return (
      <div className="cont">
        <h3 className="ttl">&#36;echo "Portfolio"</h3>
        <div className="portfolio">
          {
            PortfolioList.portfolios.map((portfolio) =>
              <div className="item">
                <Link to={ portfolio.link }>
                  <img src={ require(`../../${portfolio.thumbnail}.png`) } className="portfolio-image" alt={ portfolio.title } />
                  <p>{ portfolio.type }: { portfolio.title }</p>
                </Link>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
