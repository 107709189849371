import React from 'react';
import './styles.css';

export default class About extends React.Component{
    render() {
        return (
            <div className="cont responsive_sm">
                <h3 className="ttl">&#36;echo "About"</h3>
                <div className="header-spacer" />
                <p>
                    We plan, develop and maintain applications.
                    <br />
                    <br />
                    Utilizing our technical skills and enthusiasm,<br className="sp-hide" />
                    we build apps for new business development of our clients mainly in Asia.
                </p>
            </div>
        );

    }
}
