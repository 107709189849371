import React from 'react';
import ServiceMenu from '../../components/serviceMenu';
import './styles.css';
const pageTitle = "Service development";
const pageDescription = "We help starting up your new digital service by covering a lot of different necessary tasks including not only technical-side but also business side of things."

const serviceDevelopments = [{
    title: 'Research',
    description: 'We do research potential of a new digital service by making the most use of our experiences and connections.',
    thumbnail: 'images/serviceDevelopment/research/devlopment1',
}, {
    title: 'UX・UI design',
    description: 'Based on “User First” principle, we design product with a high usability, scalability and beautiful interface.',
    thumbnail: 'images/serviceDevelopment/uiux/devlopment2',
}, {
    title: 'Web, Mobile app dev',
    description: 'We develop Web, iOS, Android, Desktop apps and more. With our agile development method, we can handle and reflect rapid changes in situation of our customer and the market',
    thumbnail: 'images/serviceDevelopment/mobileweb/devlopment3',
}, {
    title: 'Operation',
    description: 'We maintain servers and apps to bring out the best of customers’ products. We can analyze users’ data to increase potential of the products even more.',
    thumbnail: 'images/serviceDevelopment/operation/devlopment4',
}]

export default class Service extends React.Component {
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div className="cont">
                <h3 className="ttl">&#36;echo "Service"</h3>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-1" />
                </div>
                <div id="sf_pc" className="menu-line-top"></div>
                <h3 className="page-ttl">{pageTitle}</h3>
                <p className="page-desc">{pageDescription}</p>
                <div className="service-dev-container">
                    {serviceDevelopments.map((service) =>
                        <div className="sd-item">
                            <img className="sd-item-img" src={require(`../../${service.thumbnail}@2x.jpg`)} className="sd-image" alt={service.title} />
                            <p className="sd-item-ttl">{service.title}</p>
                            <div className="sd-line"></div>
                            <p className="sd-item-desc">{service.description}</p>
                        </div>
                    )}
                </div>
                <h3 className="sf-ttl">Sample Flow</h3>
                <p className="sf-desc">From research to release, and further, we work together with you.</p>
                <img className="sf-img" id="sf_pc" src={require('../../images/serviceDevelopment/sample-flows/devlopment5_pc@2x.png')} alt="sample flow" />
                <img className="sf-img" id="sf_sp" src={require('../../images/serviceDevelopment/sample-flows/devlopment5_sp@2x.png')} alt="sample flow" />
                <div className="menu-line-bottom"></div>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-1" />
                </div>
                <div id="sf_sp">
                    <ServiceMenu tabs="tabs-1" />
                </div>
            </div>
        )
    }
}