import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import * as PortfolioList from '../../components/PortfolioList';

export default class PortfolioDetail extends React.Component {
  constructor(props) {
    super(props);

    // needed for prev and next in portfolio detail page
    this.portfolios = PortfolioList.portfolios
    this.total_portfolios = this.portfolios.length;
    this.firstPortfolio = this.portfolios[1];
    this.lastPortfolio = this.portfolios[this.total_portfolios - 1];
  }

  render() {
    const { key, type, title, description, country, tech_stack } = this.props.portfolio;
    const prev = this.props.index > 1 ? this.portfolios[this.props.index-1].link : this.lastPortfolio.link;
    const next = this.props.index < this.total_portfolios - 1 ? this.portfolios[this.props.index+1].link : this.firstPortfolio.link;

    return (
      <div className="cont">
        <h3 className="ttl">{ type }: { title }</h3>
        <p className="info">
          Country: { country }<br />
          Tech Stack: { tech_stack }
        </p>
        <p className="desc">{ description }</p>
        <div className="portfolio portfolio-item single-item">
          <div className="item">
            <img src={ require(`../../images/portfolio/${key}/image1.png`) } className="portfolio-image" alt={title} />
          </div>
        </div>
        <div className="portfolio portfolio-item">
          <div className="item">
            <img src={ require(`../../images/portfolio/${key}/image2.png`) } className="portfolio-image" alt={title} />
          </div>
          <div className="item">
            <img src={ require(`../../images/portfolio/${key}/image3.png`) } className="portfolio-image" alt={title} />
          </div>
        </div>
        <div className="line"></div>
        <div className="pagination">
          <div className="item">
            <Link to={prev}>{"<="} Previous</Link>
          </div>
          <div className="item">
            <Link to="/portfolio">View list</Link>
          </div>
          <div className="item">
            <Link to={next}>Next {"=>"}</Link>
          </div>
        </div>
      </div>
    );
  }
}
