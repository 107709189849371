import React from 'react';
import './styles.css';

export default class Welcome extends React.Component {
  render() {
    return (
    	<div className="cont">
	        <h3 className="ttl">&#36;echo "Welcome"</h3>
	        <p className="">
						This is a corporate website of Namespace Inc.<br />
	        </p>
	      </div>
    );
  }
}
