import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import Homepage from './containers/homepage/Index';

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Homepage />
        </BrowserRouter>
    );
  }
}

export default App;
