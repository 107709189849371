import React from 'react';
import './styles.css';
import axios from 'axios';
import $ from 'jquery';

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      content: "",
      nameInvalidStyle: true,
      emailInvalidStyle: true,
      contentInvalidStyle: true,
      emailValid: false,
      nameValid: false,
      contentValid: false,
      formValid: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  postForm = (e) => {
    e.preventDefault();
    const payload = {
      name: this.state.name,
      email: this.state.email,
      content: this.state.content
    }
    axios
      .post(
        "https://hooks.slack.com/services/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",
        JSON.stringify({
          "blocks": [
            {
              "type": "section",
              "text": {
                "type": "plain_text",
                "text": ":email: A Contact is submitted.",
                "emoji": true
              }
            },
            {
              "type": "section",
              "text": {
                "type": "mrkdwn",
                "text": `*${payload.name}* | ${payload.email}`
              }
            },
            {
              "type": "divider"
            },
            {
              "type": "section",
              "text": {
                "type": "plain_text",
                "text": `${payload.content}`,
                "emoji": true
              }
            }
          ]
        })
       )
      .then(
        function (data) {
          $("#contactFrom").fadeOut(500, function () {
            $(".thanks").removeClass('hide');
          });
        }
      )
      .catch(
        function (xhr, status, err) {
          $("#contactFrom").fadeOut(500, function () {
            $(".error").removeClass('hide');
          });
        }
      )
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
      [name + 'InvalidStyle']: false,
    },
      () => { this.validateField(name, value) }
    );
  }

  validateField(fieldName, value) {
    let nameValid = this.state.nameValid;
    let emailValid = this.state.emailValid;
    let contentValid = this.state.contentValid;

    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 1;
        this.setState({
          nameInvalidStyle: nameValid
        })
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        this.setState({
          emailInvalidStyle: emailValid
        })
        break;
      case 'content':
        contentValid = value.length >= 1;
        this.setState({
          contentInvalidStyle: contentValid
        })
        break;
      default:
        break;
    }
    this.setState({
      nameValid: nameValid,
      emailValid: emailValid,
      contentValid: contentValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({ formValid: this.state.nameValid && this.state.emailValid && this.state.contentValid });
  }

  render() {
    return (
      <div className="cont">
        <h3 className="ttl">$echo "Contact"</h3>
        <div className="header-spacer" />
        <form id="contactFrom" onSubmit={this.postForm}>
          <div className="form-group">
            <label>Your name</label>
            <input
              type="text"
              name="name"
              className={'form-control' + (this.props.opening ? ' gray' : '') + (this.state.nameInvalidStyle ? '' : ' invalid')}
              id="formGroupExampleInput"
              placeholder=""
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label>Your email</label>
            <input
              type="text"
              name="email"
              className={'form-control' + (this.props.opening ? ' gray' : '') + (this.state.emailInvalidStyle ? '' : ' invalid')}
              id="formGroupExampleInput2"
              placeholder=""
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea
              name="content"
              className={'form-control' + (this.props.opening ? ' gray' : '') + (this.state.contentInvalidStyle ? '' : ' invalid')}
              id="exampleTextarea"
              onChange={this.handleChange}>
            </textarea>
          </div>
          <button
            className={'btn btn-primary submit' + (this.props.opening ? ' gray' : '')}
            disabled={!this.state.formValid}
          >
            Submit
          </button>
        </form>
        <p className="thanks hide">Thanks for your inquiry!</p>
        <p className="error hide">An error occurred.</p>
      </div>
    );
  }
}