import React from 'react';
import ServiceMenu from '../../components/serviceMenu';
import './styles.css';
const pageTitle = "AI development";
const pageDescription = "We develop AI models with machine learning or deep learning with your internal data or external data available in public.";
const aiDevelopmentKinds = [{
    title: "Natural language",
    description: "FAQ, Customers reviews, Articles, Documents, etc.",
    thumbnail: "images/aiDevelopment/natural-language/ai_icon1",
}, {
    title: "Images and videos",
    description: "Buildings, Sceneries, Goods, Handwritings, etc…",
    thumbnail: "images/aiDevelopment/image-videos/ai_icon3",
}, {
    title: "Audio",
    description: "Meeting records, Conversations, Music, Noises, etc…",
    thumbnail: "images/aiDevelopment/audio/ai_icon2",
}, {
    title: "Time series data",
    description: "Stock prices, sales, temperatures, ",
    thumbnail: "images/aiDevelopment/time/ai_icon4",
}]
export default class Service extends React.Component {
    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div className="cont">
                <h3 className="ttl">&#36;echo "Service"</h3>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-2" />
                </div>
                <div id="sf_pc" className="menu-line-top"></div>
                <h3 className="page-ttl">{pageTitle}</h3>
                <p className="page-desc">{pageDescription}</p>

                <p className="ai-sub-desc">Cognition and prediction are what AI is good at.<br />We utilize these skills of AI to solve many problems of business scenes and meet end-users’ needs.</p>
                <img className="ai-img" src={require('../../images/aiDevelopment/ai/ai1@2x.png')} />

                <p className="ai-sub-desc">We develop solutions from various kinds of data</p>
                {aiDevelopmentKinds.map((ai) =>
                    <div className="ai-kind-container">
                        <img className="ai-kind-img" src={require(`../../${ai.thumbnail}@2x.png`)} alt={ai.title} />
                        <p className="ai-kind-ttl">{ai.title}</p>
                        <p className="ai-kind-desc">{ai.description}</p>
                    </div>
                )}

                <p className="ai-sub-desc">AI can be applied in many different kinds of fields.</p>
                <img className="img-pc" id="sf_pc" src={require('../../images/aiDevelopment/ai-fields/ai2_pc@2x.png')} />
                <img className="sf-img" id="sf_sp" src={require('../../images/aiDevelopment/ai-fields/ai2_sp@2x.png')} />

                <h3 className="ai-sf-ttl">Sample Flow</h3>
                <p className="sf-desc">We can fully support you with proposal for new business with AI, development of AI and maintenance of AI.</p>
                <img className="sf-img" id="sf_pc" src={require('../../images/aiDevelopment/sample-flow/ai3_pc@2x.png')} />
                <img className="sf-img" id="sf_sp" src={require('../../images/aiDevelopment/sample-flow/ai3_sp@2x.png')} />

                <div className="menu-line-bottom"></div>
                <div id="sf_pc">
                    <ServiceMenu tabs="tabs-2" />
                </div>
                <div id="sf_sp">
                    <ServiceMenu tabs="tabs-2" />
                </div>
            </div>
        )
    }
}