import React, { Component } from 'react'
import $ from 'jquery';
import './Style.css';
import Menu from '../../components/menu/Index';
import MenuItem from '../../components/menuitem/Index';
import Footer from '../pages/footer'
import { withRouter } from 'react-router-dom';

class Homepage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opening: false,
      width: 0,
      location: this.props.location.pathname
    }
    this.openingToggle = this.openingToggle.bind(this);
  }
  componentDidMount() {
    this.setState({
      width: $(window).width()
    });
  }
  openingToggle(flag) {
    if (this.state.width < 640) {
      this.setState({
        opening: !this.state.opening
      })
    }
  }
  render() {
    return (
      <div className={'wrap ' + (this.state.opening ? 'gray' : '')}>
        <div className="wrapper">
          <div className="container-withFooter">
            <div className="container-homepage">
              <div className="center-container">
              <Menu
                openingToggle={this.openingToggle}
                opening={this.state.opening}
                width={this.state.width}
              />
              <MenuItem
                opening={this.state.opening}
                locationPath={this.state.location}
              />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Homepage);
