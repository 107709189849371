import React from 'react';
import './styles.css';
import axios from 'axios';

export default class Recruitment extends React.Component{
    constructor(props) {
        super(props);
        
        this.state = {
            vacancy: [],
        };
    }

    componentDidMount() {
        axios.get('https://raw.githubusercontent.com/namespace-team/namespace_jsons/master/ns_corp-status.json')
          .then(response => this.setState({ vacancy: Object.values(response.data) }))
          .catch(error => console.error('Recruitment positions fetch error', error));
    }

    render() {
        return (
            <div className="cont responsive_sm">
                <h3 className="ttl">&#36;echo "Recruitment"</h3>
                <div className="header-spacer" />
                <p>Below is the outline of how our hiring system works.</p>
                <div className="header-spacer" />
                <p className="recruitment-ttl-underline">Vacant Positions</p>

                {/* List of vacant positions */}
                {this.state.vacancy.map(job => {
                    if(job.status) return(
                        <div key={job.position} className="recruitment-job-container">
                            <p className="recruitment-jobs">{job.position} - {job.seat}</p>
                            <a href={job.url} target="_blank"><p className="recruitment-link">Job description</p></a>
                            <div className="recruitment-hr" />
                        </div>
                    )
                })}

                <div className="header-spacer" />
                <p className="recruitment-ttl">Hiring process</p>
                <div>
                <p className="hiring-process-info">Candidate will be notified in each step through email.</p>
                </div>
                <div className="hiring-process">
                    <div className="hiring-process-bullet">
                        <p className="hiring-process-ttl">01</p>
                        <div className="hiring-process-vr" />
                    </div>
                    <div>
                        <p className="hiring-process-ttl">Application</p>
                        <p >Only the candidates who follow specific criteria as explained in the job description will be selected for further processing.</p>

                    </div>
                </div>
                <div className="header-spacer" />
                <div className="hiring-process">
                    <div className="hiring-process-bullet">
                        <p className="hiring-process-ttl">02</p>
                        <div className="hiring-process-interview-vr" />
                    </div>
                    <div>
                        <p className="hiring-process-ttl">Tests</p>
                        <div className="hiring-list">
                            <p>Technical tests</p>
                        </div>
                    </div>
                </div>
                <div className="header-spacer" />
                <div className="hiring-process">
                    <div className="hiring-process-bullet">
                        <p className="hiring-process-ttl">03</p>
                        <div className="hiring-process-final-vr" />
                    </div>
                    <div>
                        <p className="hiring-process-ttl">Final Interview</p>
                        <p>Done by CEOs of Nepal and Japan.</p>
                    </div>
                </div>
            </div>
        );

    }
}
