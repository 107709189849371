export const portfolios = [
  {
    key: 'wwx',
    type: 'Blockchain',
    title: 'NEO based token',
    description: 'A NEP-5 based NEO token. It has been deployed in mainnet of NEO blockchain and can be exchaged in a crypto exchange app operated by the client in Singapore.',
    link: '/portfolio-wwx',
    thumbnail: 'images/portfolio/wwx/image1',
    country: 'Singapore',
    tech_stack: 'NEP-5, neo-cli, neo-python'
  },
  {
    key: 'iot-camera-system',
    type: 'IoT',
    title: 'Security camera system',
    description: 'An independent surveilance camera system that can be set up in areas where there is no electrical infrastructure. A microcomputer powered by solar energy connects to the Internet and controls surveillance cameras and LED lights. Customers can check the video of the camera anytime on our web app.',
    link: '/portfolio-iot-camera-system',
    thumbnail: 'images/portfolio/iot-camera-system/image1',
    country: 'Japan',
    tech_stack: 'Raspberry Pi, Javascript, Python'
  },

  {
    key: 'shoemaker',
    type: 'Mobile app',
    title: 'Order made shoes',
    description: 'A custom-made shoes ordering application. Customers can design and order a unique pair of shoes that meets their need from iOS or Android app.',
    link: '/portfolio-shoemaker',
    thumbnail: 'images/portfolio/shoemaker/image1',
    country: 'Japan',
    tech_stack: 'Ruby on Rails, React Native'
  },
  {
    key: 'payment-service',
    type: 'Mobile app',
    title: 'Payment app',
    description: 'A payment mobile application for public transportation. Customers purchase a QR code ticket with their smartphone app and scan it on our iPad app at station.',
    link: '/portfolio-payment-service',
    thumbnail: 'images/portfolio/payment-service/image1',
    country: 'Japan',
    tech_stack: 'Ruby on Rails, React Native'
  },
  {
    key: 'line',
    type: 'Web app',
    title: 'Applicant tracking system',
    description: 'An applicant tracking system integrated with LINE@ API, which is one of the most famous chat apps in Japan. It has applicant management, bulk message, message bot, one-on-one message, and event management.',
    link: '/portfolio-line',
    thumbnail: 'images/portfolio/line/image1',
    country: 'Japan',
    tech_stack: 'Ruby on Rails, jQuery'
  },
  {
    key: 'bulk-registration-service',
    type: 'Web app',
    title: 'Bulk registration system',
    description: 'A web form to register for many job hunting websites all at once. Core part of the frontend is built in Vue.js',
    link: '/portfolio-bulk-registration-service',
    thumbnail: 'images/portfolio/bulk-registration-service/image1',
    country: 'Japan',
    tech_stack: 'Vue.js'
  },
  {
    key: 'hakirei-cms',
    type: 'Web app',
    title: 'CMS',
    description: 'A media site using our own CMS made in Rails. Admin can edit articles by CKEditor.',
    link: '/portfolio-hakirei-cms',
    thumbnail: 'images/portfolio/hakirei-cms/image1',
    country: 'Japan',
    tech_stack: 'Ruby on Rails, jQuery'
  },
  {
    key: 'techrise',
    type: 'Web app',
    title: 'Programming e-learning',
    description: 'A programming e-learning platform for Southeast Asia. The frontend is partly implemented with React, a JavaScript Library.',
    link: '/portfolio-techrise',
    thumbnail: 'images/portfolio/techrise/image1',
    country: 'Nepal',
    tech_stack: 'Ruby on Rails, React'
  },
  {
    key: 'rss',
    type: 'Web app',
    title: 'RSS Crawling system',
    description: 'A system that acquires articles from RSS of curated medias around the world, translates and stores them in the client-operated media.Translation is automated with Google Translation API. Manual proofreading is also possible.',
    link: '/portfolio-rss',
    thumbnail: 'images/portfolio/rss/image1',
    country: 'Singapore',
    tech_stack: 'Ruby on Rails, Python, Flask'
  },
  {
    key: 'apparel',
    type: 'Website',
    title: 'Apparel company',
    description: 'A corporate website of an apparel IT manufacturer in Japan. The new website with a simple design helps brand-building.',
    link: '/portfolio-apparel',
    thumbnail: 'images/portfolio/apparel/image1',
    country: 'Japan',
    tech_stack: 'EJS, Sass'
  },
  {
    key: 'token-news',
    type: 'Website',
    title: 'Blockchain company',
    description: 'A corporate website of a Blockchain company in Philippines. It is implemented with Wordpress, and the client can publish press release or news by themselves.',
    link: '/portfolio-token-news',
    thumbnail: 'images/portfolio/token-news/image1',
    country: 'Philippines',
    tech_stack: 'Wordpress'
  },
  {
    key: 'cruise',
    type: 'Website',
    title: 'Luxury cruising service',
    description: 'A service website of a company that is developing a cruising business. Since they handle luxury cruisers, we designed the page elegant. Customers can make reservations and payments online.',
    link: '/portfolio-phil',
    thumbnail: 'images/portfolio/cruise/image1',
    country: 'Japan',
    tech_stack: 'HTML, Sass, PHP'
  },
  {
    key: 'n-plus',
    type: 'Website',
    title: 'Battery maker',
    description: 'A corporate website of a battery manufacturer in Singapore. It is designed to help their main product look more attractive and the corporate philosophy visible.',
    link: '/portfolio-n-plus',
    thumbnail: 'images/portfolio/n-plus/image1',
    country: 'Singapore',
    tech_stack: 'HTML, Sass, PHP'
  },
 {
    key: 'shoemaker-web',
    type: 'Website',
    title: 'Order made shoes',
    description: 'A custom-made shoes ordering website. It is designed as a landing page to show advantages of custom-made shoes. It helps people who are interested in the shoes to purchase it. The website also has a function that customers can reserve a measurement for their shoe size.',
    link: '/portfolio-shoemaker-web',
    thumbnail: 'images/portfolio/shoemaker-web/image1',
    country: 'Japan',
    tech_stack: 'EJS, Sass'
  },
  {
    key: 'liberty',
    type: 'Website',
    title: 'Political web media',
    description: 'A media site featuring political news mainly from Japan and Asia-Pacific. It uses Wordpress as a CMS so the client can operate it by themselves without special knowledge. Internationalization is also implemented so the client can easily upload multilingual articles from Wordpress admin.',
    link: '/portfolio-liberty',
    thumbnail: 'images/portfolio/liberty/image1',
    country: 'US',
    tech_stack: 'Wordpress'
  },
 
  {
    key: 'realestate',
    type: 'Website',
    title: 'Realestate venture company',
    description: 'A recruitment website for a real estate venture company. As the opening animation on the top page, we adopted a short video and a slider to convey a passionate atmosphere in the company. The company history page is displayed in time series with animation.',
    link: '/portfolio-realestate',
    thumbnail: 'images/portfolio/realestate/image1',
    country: 'Japan',
    tech_stack: 'HTML, Sass, PHP'
  },
  {
    key: 'kenjaya',
    type: 'Website',
    title: 'Shared workspace provider',
    description: 'A service website for a co-working space. We have designed a smartphone page so customers can quickly and easily access any information.',
    link: '/portfolio-kenjaya',
    thumbnail: 'images/portfolio/kenjaya/image1',
    country: 'Japan',
    tech_stack: 'Ruby on Rails'
  },

  {
    key: 'daiyu',
    type: 'Website',
    title: 'Waterworks company',
    description: 'A recruitment website for a waterworks contractor. Under the concept of “You can be a hero”, we arranged employees into heroes with illustrations. We have designed the site to give the applicants a different impression from other companies in the same industry.',
    link: '/portfolio-waterworks',
    thumbnail: 'images/portfolio/daiyu/image1',
    country: 'Japan',
    tech_stack: 'HTML, Sass, PHP'
  }
];
