export const services = [{
    title: 'Service development',
    thumbnail: 'images/service/service-development/service_icon1',
    link: 'service-development',
    tabs: 'tabs-1',
  }, {
    title: 'AI development',
    thumbnail: 'images/service/ai-development/service_icon2',
    link: 'ai-development',
    tabs: 'tabs-2',
  }, {
    title: 'RPA consulting',
    thumbnail: 'images/service/rpa-consulting/service_icon3',
    link: 'rpa-consulting',
    tabs: 'tabs-3',
  }, {
    title: 'Branding',
    thumbnail: 'images/service/branding/service_icon4',
    link: 'branding',
    tabs: 'tabs-4',
  }, {
    title: 'E Commerce',
    thumbnail: 'images/service/e-commerce/service_icon5',
    link: 'ecommerce',
    tabs: 'tabs-5',
  }]
  
