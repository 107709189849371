import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import Welcome from '../../containers/pages/welcome';
import About from '../../containers/pages/about';
import Portfolio from '../../containers/pages/portfolio';
import * as PortfolioList from '../PortfolioList';
import * as ServiceList from '../ServiceList';
import PortfolioDetail from '../../containers/pages/PortfolioDetail';
import Company from '../../containers/pages/company';
import Contact from '../../containers/pages/contact';
import Service from '../../containers/pages/service';
import ServiceDevelopment from '../../containers/pages/serviceDevelopment';
import AIDevelopment from '../../containers/pages/aiDevelopment'
import RPAConsulting from '../../containers/pages/rpaConsulting'
import Branding from '../../containers/pages/branding'
import Ecommerce from '../../containers/pages/ecommerce'
import Recruitment from '../../containers/pages/recruitment';

import './Style.css';
import posed, { PoseGroup } from "react-pose";

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

export default class MenuItem extends Component {
  render() {
    return (
      <Route
        render={({ location }) => (
          <PoseGroup>
            <RouteContainer key={location.pathname}>
              <Switch location={location}>
                <Route path="/" exact component={Welcome}></Route>
                <Route path="/about" component={About}></Route>
                <Route path="/service" component={Service}></Route>
                <Route path="/company" component={Company}></Route>
                <Route path="/portfolio" component={Portfolio}></Route>
                <Route path="/service-development" component={ServiceDevelopment}></Route>
                <Route path="/ai-development" component={AIDevelopment}></Route>
                <Route path="/rpa-consulting" component={RPAConsulting}></Route>
                <Route path="/branding" component={Branding}></Route>
                <Route path="/ecommerce" component={Ecommerce}></Route>
                <Route path="/recruitment" component={Recruitment}></Route>
                
                {
                  PortfolioList.portfolios.map((portfolio, index) =>
                    <Route
                      key={portfolio.key}
                      exact path={portfolio.link}
                      component=
                      {() =>
                        <PortfolioDetail
                          portfolio={portfolio}
                          index={index}
                        />
                      }
                    />
                  )
                }

                <Route path="/contact" render={(routeProps) => (
                  <Contact {...routeProps} {...this.props} />
                )}></Route>
              </Switch>
            </RouteContainer>
          </PoseGroup>
        )}
      />
    )
  }
}
