import React from "react";
import ServiceMenu from "../../components/serviceMenu";
import "./styles.css";
const pageTitle = "E-commerce";
const pageDescription =
  "We develop and support the operation of various types of E-Commerce websites: B2B, D2C, subscription, and cross-border.";
const opengatellc = "https://open-gate.net/";

export default class Ecommerce extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  render() {
    return (
      <div className="cont">
        <h3 className="ttl">&#36;echo "Service"</h3>
        <div id="sf_pc">
          <ServiceMenu tabs="tabs-5" />
        </div>
        <div id="sf_pc" className="menu-line-top" />
        <h3 className="page-ttl">{pageTitle}</h3>
        <p className="page-desc">{pageDescription}</p>
        <h3 className="e-dev-ttl">EC development</h3>
        <p className="e-dev-desc">
          We develop E-Commerce websites using Shopify, which is an E-Commerce
          platform for online stores used in 175 countries, and provide
          comprehensive support for subsequent website improvement and sales
          channel expansion.
        </p>

        <div className="dev-container">
          <div className="dev-sub-container">
            <img
              id="sf_pc"
              className="e-img"
              src={require("../../images/ecommerce/development-01/ec_dev1@2x.png")}
            />
            <img
              id="sf_sp"
              className="e-img"
              src={require("../../images/ecommerce/development-01/ec_dev1_sp@2x.png")}
            />
            <p className="e-desc">
              e.g.
              <br />
              ・Develop E-Commerce website
              <br /> based on Shopify
              <br />
              　・Product management system
              <br />
              　・Shopping cart function
              <br />
              　・Payment system
              <br />
              　・Order management system
              <br />
              　・Customer management system
              <br />
              ・Design interface
              <br />
            </p>
          </div>
          <div className="dev-sub-container">
            <img
              id="sf_pc"
              className="e-img"
              src={require("../../images/ecommerce/development-02/ec_dev2@2x.png")}
            />
            <img
              id="sf_sp"
              className="e-img"
              src={require("../../images/ecommerce/development-02/ec_dev2_sp@2x.png")}
            />
            <p className="e-desc">
              e.g.
              <br />
              ・Collect / analyse data
              <br />
              　・Sales data
              <br />
              　・Revenue data
              <br />
              　・Purchase history
              <br />
              　・Reviews
              <br />
              ・Add recommendation function
              <br />
              ・Add point card function
              <br />
              ・Add e-mail delivery function
              <br />
              ・Improve interface
              <br />
            </p>
          </div>
          <div className="dev-sub-container">
            <img
              id="sf_pc"
              className="e-img"
              src={require("../../images/ecommerce/development-03/ec_dev3@2x.png")}
            />
            <img
              id="sf_sp"
              className="e-img"
              src={require("../../images/ecommerce/development-03/ec_dev3_sp@2x.png")}
            />
            <p className="e-desc">
              e.g.
              <br />
              ・Social commerce
              <br />
              ・Subscription
              <br />
              ・Mobile app
              <br />
            </p>
          </div>
        </div>
        <div id="sf_sp" className="menu-line-top" />
        <h3 className="e-dev-ttl">EC Consulting</h3>
        <p className="e-dev-desc">
          In collaboration with Opengate LLC, which is an expert in EC
          consulting, we support everything from planning EC business strategies
          to implementing measures to maximize sales.
        </p>

        <div className="e-p">Opengate LLC</div>
        <div className="e-address-container">
          <div>
            <img
              style={{ height: "208px", width: "208px" }}
              src={require("../../images/ecommerce/opengate-logo/logo_open@2x.png")}
            />
          </div>
          <p className="e-address-desc">
            Address:
            <br />
            141-0022
            <br />
            2-10-1, Higashigotanda, Shinagawa, Tokyo, Japan
            <br />
            Business overview:
            <br />
            ・Management consulting
            <br />
            ・Comprehensive EC consulting
            <br />
            ・Cross-border EC consulting
            <br />
            ・Planning of corporate strategy / Corporate innovation /
            Construction of the corporate information system
            <br />
            ・Support for M&A
            <br />
            ・Human resource introduction in EC field
            <br />
          </p>
        </div>

        <p id="sf_pc" className="e-consulting-p">
          EC consulting service overview
        </p>
        <div className="e-consulting-container">
          <div className="e-consulting-sub-container">
            <img
              id="sf_pc"
              className="e-img"
              src={require("../../images/ecommerce/consulting-01/ec_marketing1@2x.png")}
            />
            <img
              id="sf_sp"
              className="e-img"
              src={require("../../images/ecommerce/consulting-01/ec_marketing1_sp@2x.png")}
            />
            <p className="e-desc">
              ・Channel-specific EC strategy
              <br />
              　 planning
              <br />
              　・Owned EC
              <br />
              　・Mall development
              <br />
              　　(Amazon / Yahoo! etc.)
              <br />
              　・Overseas expansion
              <br />
              　　(China / Southeast-Asia)
              <br />
              ・KGI planning
              <br />
              ・Marketing strategy planning
              <br />
              ・Organizational design in EC area
              <br />
            </p>
          </div>
          <div className="e-consulting-sub-container">
            <img
              id="sf_pc"
              className="e-img"
              src={require("../../images/ecommerce/consulting-02/ec_marketing2@2x.png")}
            />
            <img
              id="sf_sp"
              className="e-img"
              src={require("../../images/ecommerce/consulting-02/ec_marketing2_sp@2x.png")}
            />
            <p className="e-desc">
              ・Develop your own EC site
              <br />
              ・Planning specific measures by mall
              <br />
              ・Advertising / promotional planning
              <br />
              ・Measures by advertising menu /<br />
              　　KPI
              <br />
              ・Budget planning
              <br />
              ・Social marketing
              <br />
              　(SNS / live commerce)
              <br />
            </p>
          </div>
          <div className="e-consulting-sub-container">
            <img
              id="sf_pc"
              className="e-img"
              src={require("../../images/ecommerce/consulting-03/ec_marketing3@2x.png")}
            />
            <img
              id="sf_sp"
              className="e-img"
              src={require("../../images/ecommerce/consulting-03/ec_marketing3_sp@2x.png")}
            />
            <p className="e-desc">
              ・KPI tracking
              <br />
              ・Improvement of advertising
              <br />
              　/ sales promotion operations
              <br />
              ・Introduction of human resources
              <br />
              　to strengthen EC business
              <br />
            </p>
          </div>
        </div>

        <div className="menu-line-bottom" />
        <div id="sf_pc">
          <ServiceMenu tabs="tabs-5" />
        </div>
        <div id="sf_sp">
          <ServiceMenu tabs="tabs-5" />
        </div>
      </div>
    );
  }
}
