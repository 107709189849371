import React from 'react';
import './styles.css';
import * as ServicesList from '../../components/ServiceList';
import { Link } from 'react-router-dom';

export default class Service extends React.Component {
  render() {
    return (
      <div className="cont">
        <h3 className="ttl">&#36;echo "Service"</h3>
        <div className="service-container">
          {ServicesList.services.map((service) =>
            <div className="service-item">
              <img src={require(`../../${service.thumbnail}.png`)} className="service-image" alt={service.title} />
              <p className="service-item-ttl">{service.title}</p>
              <Link to={service.link} className="service-item-link"><u>Read more &gt;</u></Link>
            </div>
          )}
        </div>
      </div>
    )
  }
}